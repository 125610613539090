import ParseContent from 'components/shared/ParseContent'
import React from 'react'
import styled from 'styled-components'

// Components

interface VideoProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Video
  location?: any
}

const StyledIframe = styled.iframe`
  position: relative;
  height: 500px;

  @media (max-width: 1199.98px) {
    height: 350px;
  }
  @media (max-width: 991.98px) {
  }
  @media (max-width: 767.98px) {
  }
  @media (max-width: 575.98px) {
    height: 200px;
  }
`

const Video: React.FC<VideoProps> = ({ fields, location = {} }) => (
  <section className="container">
    <div className="row mb-sm-5 justify-content-center pb-sm-5">
      <div className="col-lg-8 d-flex flex-column align-items-center justify-content-center">
        <ParseContent className="mb-3" content={fields.description} />
        <StyledIframe
          src={fields.vimeoUrl}
          width="100%"
          height="500"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Video"
          className="w-full"
        />
      </div>
    </div>
  </section>
)

export default Video
